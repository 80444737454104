.room-features-mobile{
    display: none;
}
.room-features-bullet-div{
    display: inline-flex;
}
.room-features{
    background-color: #ecf2f7;
}
.room-features-bullet-text{
    font-family: Objektiv Mk1 Trial;
    font-weight: 600;
    font-size: 0.9rem;
    color: #505457;
}
.room-features-bullet-img{
    margin-right: 10px;
}
.room-features-title{
    font-family: Objektiv Mk1 Trial;
    font-weight: 700;
    font-size: 1rem;
    color: #2789CA;
}
.room-features-web{
    padding-left: 5%;
    padding-right: 5%;
}
.room-features-bullet-img{
    height: fit-content;
}
@media (max-width:985px) {
    .room-features-web{
        padding-left: 3%;
        padding-right: 3%;
    }
}
@media (max-width:700px) {
    .room-features-mobile{
        display: block!important;
    }
    .room-features-web{
        display: none!important;
    }
    .room-features-mobile-grid{
        width: 290px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width:300px) {
    .room-features-mobile-grid{
        width: unset!important;
        margin-left: auto;
        margin-right: auto;
        height: fit-content;
    }
    .room-features-bullet-img{
        height: fit-content;
    }
}