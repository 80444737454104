.image-carousel{
  display: flex;
	align-items: center;
	min-height: 100vh;
}
.slider{width: 100%}
.slider input{display: none;}
.testimonials{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	min-height: 350px;
	perspective: 1000px;
	overflow: hidden;
}
.testimonials .item{
	width: 227px;
	position: absolute;
	top: 0;
	box-sizing: border-box;
	text-align: center;
	transition: transform 0.4s;
	box-shadow: 0 0 10px rgba(0,0,0,0.3);
	user-select: none;
	cursor: pointer;
}
.testimonials .item h1{font-size: 114px; color: white;}
.dots{display: flex; justify-content: center;align-items: center;}
.dots label {
  height: 3px;
  width: 29px;
  border-radius: 19%;
  cursor: pointer;
  background-color: #2789CA;
  background-color: #7BBAE44D;
  margin: 7px;
  transition-duration: 0.2s;
}
.dots label.active {
  background-color: #2789CA;
}
#t-1:checked ~ .dots label[for="t-1"],
#t-2:checked ~ .dots label[for="t-2"],
#t-3:checked ~ .dots label[for="t-3"],
#t-4:checked ~ .dots label[for="t-4"],
#t-5:checked ~ .dots label[for="t-5"]{
	transform: scale(2);
	background-color: #fff;
	box-shadow: 0px 0px 0px 3px #dddddd24;
}

#t-1:checked ~ .dots label[for="t-2"],
#t-2:checked ~ .dots label[for="t-1"],
#t-2:checked ~ .dots label[for="t-3"],
#t-3:checked ~ .dots label[for="t-2"],
#t-3:checked ~ .dots label[for="t-4"],
#t-4:checked ~ .dots label[for="t-3"],
#t-4:checked ~ .dots label[for="t-5"],
#t-5:checked ~ .dots label[for="t-4"]{
	transform: scale(1.5);
}

#t-1:checked ~ .testimonials label[for="t-3"],
#t-2:checked ~ .testimonials label[for="t-4"],
#t-3:checked ~ .testimonials label[for="t-5"],
#t-4:checked ~ .testimonials label[for="t-1"],
#t-5:checked ~ .testimonials label[for="t-2"]{
	transform: translate3d(246%, 0, -180px) rotateY(-25deg);
	z-index: 2;
}

#t-1:checked ~ .testimonials label[for="t-2"],
#t-2:checked ~ .testimonials label[for="t-3"],
#t-3:checked ~ .testimonials label[for="t-4"],
#t-4:checked ~ .testimonials label[for="t-5"],
#t-5:checked ~ .testimonials label[for="t-1"]{
	transform: translate3d(112%, 0, -90px) rotateY(-15deg);
	z-index: 3;
}

#t-2:checked ~ .testimonials label[for="t-1"],
#t-3:checked ~ .testimonials label[for="t-2"],
#t-4:checked ~ .testimonials label[for="t-3"],
#t-5:checked ~ .testimonials label[for="t-4"],
#t-1:checked ~ .testimonials label[for="t-5"]{
	transform: translate3d(-112%, 0, -90px) rotateY(15deg);
	z-index: 3;
}

#t-3:checked ~ .testimonials label[for="t-1"],
#t-4:checked ~ .testimonials label[for="t-2"],
#t-5:checked ~ .testimonials label[for="t-3"],
#t-2:checked ~ .testimonials label[for="t-5"],
#t-1:checked ~ .testimonials label[for="t-4"]{
	transform: translate3d(-246%, 0, -180px) rotateY(25deg);
	
}

#t-1:checked ~ .testimonials label[for="t-1"],
#t-2:checked ~ .testimonials label[for="t-2"],
#t-3:checked ~ .testimonials label[for="t-3"],
#t-4:checked ~ .testimonials label[for="t-4"],
#t-5:checked ~ .testimonials label[for="t-4"],
#t-5:checked ~ .testimonials label[for="t-5"]{
	
	z-index: 4;
}