.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    font-size: 94px;
    font-weight: 300;
    color: white;
    left: 15%;
    content: '';
    background: url(../../Assets/img/prev.png);
    height: 80px;
    width: 30px;
    background-size: cover;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    font-size: 94px;
    font-weight: 300;
    color: white;
    left: 15%;
    content: '';
    background: url(../../Assets/img/next.png);
    height: 80px;
    width: 30px;
    background-size: cover;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 26%);
    left: auto;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 26%);
    right: auto;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 1;
    cursor: auto;
    pointer-events: inherit;
}
.swiper-button-prev, .swiper-button-next {
    top: var(--swiper-navigation-top-offset, 45%);
}
@media screen and (max-width: 500px) {
    .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
        font-size: 94px;
        font-weight: 300;
        color: white;
        left: 15%;
        content: '';
        background: url(../../Assets/img/prev_mob.png);
        height: 61px;
        width: 61px;
        background-size: cover;
    }
    
    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
        font-size: 94px;
        font-weight: 300;
        color: white;
        left: 15%;
        content: '';
        background: url(../../Assets/img/next_mob.png);
        height: 61px;
        width: 61px;
        background-size: cover;
    }

    .swiper-button-next, .swiper-rtl .swiper-button-prev {
        right: var(--swiper-navigation-sides-offset, 31%);
        z-index: 50;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
        left: var(--swiper-navigation-sides-offset, 31%);
        z-index: 50;
    }
    .swiper-button-prev, .swiper-button-next {
        position: absolute;
        top: var(--swiper-navigation-top-offset, 90%);
        width: calc(var(--swiper-navigation-size) / 13 * 27);
        height: var(--swiper-navigation-size);
        margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--swiper-navigation-color, var(--swiper-theme-color));
    }
  }

  @media screen and (max-width: 380px) { 
    .swiper-button-prev, .swiper-button-next {
        position: absolute;
        top: var(--swiper-navigation-top-offset, 90%);
        width: calc(var(--swiper-navigation-size) / 13 * 20);
        height: var(--swiper-navigation-size);
        margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--swiper-navigation-color, var(--swiper-theme-color));
    }
  }