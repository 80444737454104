.aqualina-exudes-medium{
    display: none;
}
.aqualina-exudes-mobile{
    display: none;
}
.aqualina-exudes-images{
    width: 100%;
}
.aqualina-exudes-web{
    padding: 7% 7%;
}
.aqualina-exudes-medium{
    padding: 5% 5%;
}
.aqualina-exudes-img-one{
    position: absolute;
    bottom: 0px;
}
.aqualina-exudes-column1{
    position: relative;
}
.aqualina-exudes-caption{
    color: #505457;
    font-weight: 400;
    font-size: 2.85rem;
    font-family: Dante MT Std;
    line-height: 47px;
    margin-bottom: 40px;
}
.ae-fix-width{
    width: 100%;
}
@media (max-width:1216px) {
    .aqualina-exudes-web{
        padding: 6% 6%;
    }
}
@media (max-width:1187px) {
    .aqualina-exudes-caption{
        font-size: 2.5rem;
        line-height: 45px;
    }
}
@media (max-width:1064px) {
    .aqualina-exudes-caption{
        font-size: 2rem;
        line-height: 40px;
    }
}
@media (max-width:978px) {
    .aqualina-exudes-caption{
        font-size: 1.95rem;
        line-height: 35px;
    }
}
@media (max-width:855px) {
    .aqualina-exudes-caption{
        font-size: 1.75rem;
        line-height: 30px;
    }
}
@media (max-width:738px) {
    .aqualina-exudes-web{
        display: none!important;
    }
    .aqualina-exudes-medium{
        display: block!important;
    }
}
@media (max-width:684px) {
    .aqualina-exudes-caption{
        font-size: 1.4rem;
        line-height: 24px;
    }
}
@media (max-width:579px) {
    .aqualina-exudes-medium{
        display: none!important;
    }
    .aqualina-exudes-mobile{
        display: block!important;
    }
    .aqualina-exudes-caption{
        font-size: 1.4rem;
        line-height: 24px;
    }
    .aqualina-exudes-caption{
        font-size: 2rem;
        line-height: 35px;
    }
}