.location{
    max-width: 1350px;
    margin-right: auto;
    margin-left: auto;
}
.title{
    color: #2789CA;
    font-family: Objektiv Mk1 Trial;
    font-weight: 700;
    font-size: 16px;
}
/* .modalvideo{
    width: 90vw;
    height: 70vh;
} */
/* .modalmain{
    position: relative!important;
} */
.location-image{
    width: 52.5%;
    margin-right: auto;
    margin-left: auto;
    /* position: relative; */
    z-index: -1;
}
.location-play{
    margin-left: auto;
    margin-right: auto;
    width: 6%;
    transition: 0.6s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.location-play:hover{
    /* transition: 005; */
    width: 6.5%!important;
    cursor: pointer;
    margin-left: -1px;
    margin-top: -7px;
}
.caption{
    font-family: Dante MT Std;
    font-size:700%;
    font-weight: 300;
    color: #2789CA;
    margin-top: -7%;
    
}
.text{
    color: #2789CA;
    font-size: 20px;
    font-weight: 400;
    font-family: Objektiv Mk1 Trial;
    margin-top: -2%;
}
.captionandtext{
    margin-top: 1.6%;
}
.buttext{
    color: #2789CA;
    font-weight: 500;
    font-size: 14px;
    color: #2789CA!important;
    border: 1px solid #2789CA;
}
.location-imagemobile{
    display: none;
}
.location-image-vidsect{
    position: relative;
}
.location-vid-close{
    position: absolute;
    top: 8px;
    right: 16px;
    background-color: rgb(250, 250, 250);
    padding: 2px 9px;
    border-radius: 50%;
}
.location-vid-close:hover{
    background-color: #2789CA;
    color: white;
    transition: 0.3s;
}
#myVideo{
    width: 100%;
}
@media (max-width: 700px){
    .location-play{
        top: 45%!important;
        left: 50%!important;
        transform: translate(-50%, -50%);
        width: 16%!important;
    }
    .location-image {
        display: none;
    }
    .location-imagemobile{
        display: block;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        z-index: -1;
    }
    .title{
        font-size: 0.875rem;
    }
    .text{
        font-size: 1.125rem;
    }
    .caption{
        font-size: 7.5rem;
    }
}
/* @media (max-width: 640px){
    .location-play{
        top: 50%!important;
    }
}
@media (max-width: 540px){
    .location-play{
        top: 50%!important;
    }
}
@media (max-width: 459px){
    .location-play{
        top: 45%!important;
    }
}
@media (max-width: 387px){
    .location-play{
        top: 40%!important;
    }
}
@media (max-width: 325px){
    .location-play{
        top: 35%!important;
    }
}
@media (max-width: 279px){
    .location-play{
        top: 30%!important;
    }
}
@media (max-width: 250px){
    .location-play{
        top: 25%!important;
    }
}
@media (max-width: 215px){
    .location-play{
        top: 20%!important;
    }
} */
@media (max-width: 1280px){
    .captionandtext{
        margin-top: -6.5%;
    }
}
@media (max-width: 1155px){
    .captionandtext{
        margin-top: -9%;
    }
}
@media (max-width: 949px){
    .captionandtext{
        margin-top: -11%;
    }
    /* .location-play{
        top: 40%;;
    } */
}
/* @media (max-width: 750px){
    .location-play{
        top: 35%;;
    }
} */
@media (max-width: 733px){
    .captionandtext{
        margin-top: -13%;
    }
}
@media (max-width: 645px){
    .captionandtext{
        margin-top: -15%;
    }
}
@media (max-width: 549px){
    .captionandtext{
        margin-top: -17%;
    }
}
@media (max-width: 500px){
    .captionandtext{
        margin-top: -20%;
    }
}
@media (max-width: 427px){
    .captionandtext{
        margin-top: -23%;
    }
}
@media (max-width: 380px){
    .captionandtext{
        margin-top: -25%;
    }
}
@media (max-width: 338px){
    .captionandtext{
        margin-top: -27%;
    }
    .caption{
        font-size: 6.5rem;
    }
}
@media (max-width: 284px){
    .captionandtext{
        margin-top: -24%;
    }
    .caption{
        font-size: 5rem;
    }
}