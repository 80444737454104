.welcome-caption{
    font-family: Dante MT Std;
    font-size: 3rem;
    font-weight: 500;
    line-height: 3.479rem;
    max-width: 500px;
    color: #505457;
}
.welcome-title{
    font-family: Objektiv Mk1 Trial;
    font-weight: 700;
    font-size: 16px;
    color: #505457;
}
.welcome-text{
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.875rem;
    font-family: Objektiv Mk1 Trial;
    /* text-align: justify; */
    color: #505457;
}
.welcome-but{
    position: absolute;
    bottom: 0px;
    font-size: 14px!important;
    color: #2789CA;
    border: 1px solid #2789CA;
}
.colun2{
    position: relative;
}
.imageone{
    width: 100%;
}
.imagetwo{
    width: 100%;
}
@media (max-width: 1020px){
    .welcome-text{
        line-height: 1.3rem;
        font-size: 1rem;
    }
}
@media (max-width: 970px){
    
    .welcome-caption{
        font-size: 3rem;
        line-height: 4.5rem;
    }
}
@media (max-width: 927px){
    .welcome-text{
        line-height: 1.4rem;
        font-size: 1rem;
    }
}
@media (max-width: 851px){
    .welcome-text{
        line-height: 1.5rem;
        font-size: 1rem;
    }
}
@media (max-width: 753px){
    .welcome-caption{
        font-size: 2.5rem;
        line-height: 4.5rem;

    }
    .welcome-text{
        font-size: 0.9rem;
    }
}
@media (max-width: 711px){
    .welcome-text{
        font-size: 0.88rem;
    }
}

@media (max-width: 703px){
    .welcome-mobile{
        display: block!important;
    }
    .welcome-web{
        display: none!important;
    }
    .welcome-caption{
        font-size: 3rem;
        line-height: 2.5rem;
    }
    .welcome-to-aqua{
        padding-left: 0.5rem!important;
        padding-right: 0.5rem!important;
    }
    .welcome-but{
        position: relative!important;
    }
    .welcome-title{
        font-size: 0.875rem;
    }
    .welcome-text{
        line-height: 1.8rem;
        font-size: 1rem;
    }
}
.welcome-mobile{
    display: none;
}
.img-welcome-mobile{
    width: 100%;
}
.welcome-to-aqua{
    padding-left: 50px;
    padding-right: 50px;
}