.shop-coming-soon-leftgrid{
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 10%;
}
.shop-coming-soon-mainimage{
    width: 100%;
}
.shop-coming-soon-logo{
    margin-bottom: 12%;
}
.shop-coming-soon-label{
    font-family: Objektiv Mk1 Trial;
    font-weight: 600;
    font-size: 0.875rem;
    color: #2789CA;

}
.shop-coming-soon-caption{
    font-size: 3.75rem;
    font-family: Objektiv Mk1 Trial;
    font-weight: 500;
    line-height: 4.875rem;
}
.caption-normal{
    color: #3D3D3D;
}
.caption-accent{
    color: #2789CA;
}
.shop-coming-soon-socials{
    display: inline-flex;
}
.social-icons-set{
    margin-right: 10px;
}
.social-icons-set:hover{
    cursor: pointer;
}
.shop-coming-soon-tmark{
    display: inline-flex;
}
.copywrite-text{
    color: #2789CA;
    font-family: Objektiv Mk1 Trial;
    font-size: 0.813rem;
    font-weight: 500;
}
.shop-coming-soon-form{
    display:flex;
    flex-direction:row;
    margin-top: 30px;
}
.shop-coming-soon-input{
    flex-grow:2;
    border: 1px solid #2789CA;
    border-radius: 10px;
    position: relative;
    height: 56px;
    margin-top: -3px;
    background-color: #EEF6FB;
    color: #00000066;
    font-family: Objektiv Mk1 Trial;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
}
.shop-coming-soon-input:focus, input:focus{
    outline: none!important;
    outline: 0px solid red!important;
}
.shop-coming-soon-button{
    background-color: #2789CA;
    padding: 13px 25px;
    color: white;
    border: 0px solid grey;
    position: absolute;
    right: 3px;
    border-radius: 6px;
    font: Objektiv Mk1 Trial;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
}
.shop-coming-soon-button:hover{
    background-color: #0172bd;
}
.coming-soon-form{
    margin-bottom: 120px;
}
@media (max-width:1163px) {
    .shop-coming-soon-caption{
        font-size: 3rem;
    }
    .coming-soon-form{
        margin-bottom: 60px;
    }
}
@media (max-width:977px) {
    .shop-coming-soon-leftgrid{
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 5%;
    }
    .shop-coming-soon-caption{
        font-size: 2.6rem;
        line-height: 3rem;
    }
}
@media (max-width:860px) {
    .shop-coming-soon-leftgrid{
        padding-left: 10%;
        padding-right: 8%;
        padding-top: 3%;
    }
    .shop-coming-soon-logo{
        width: 55%;
    }
    .shop-coming-soon-caption{
        font-size: 2.6rem;
        line-height: 3rem;
    }
}
@media (max-width:750px) {
    .shop-coming-soon-logo{
        width: 48%;
    }
    .shop-coming-soon-caption{
        font-size: 2rem;
        line-height: 2.75rem;
    }
    .coming-soon-form{
        margin-bottom: 30px;
    }
}
@media (max-width:648px) {
    .shop-coming-soon-input{
        font-size: 1rem;
        line-height: 1rem;
        height: 48px;
    }
    .shop-coming-soon-button{
        font-size: 1rem;
        line-height: 1rem;
    }
}
@media (max-width:648px) {
    .shop-coming-soon-web{
        display: none!important;
    }
    .shop-coming-soon-mobile{
        display: block!important;
    }
}
.shop-coming-soon-mobile{
    display: none;
}