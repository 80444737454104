.about-us-image{
    width: -webkit-fill-available;
}
.about-us-text{
    font-family: Objektiv Mk1 Trial;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    color: #505457;

}
.about-us-text-div{
    margin-top: auto;
    margin-bottom: auto;
}
.caption-section{
    width: 55%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    margin-left: auto;
}
.joie-caption{
    color: #2789CA;
    font-weight: 400;
    font-size: 2.375rem;
    font-family: Amalfi Coast;
    margin-bottom: 40px;
}
.joie-captext{
    font-family: Objektiv Mk1 Trial;
    font-weight: 400;
    font-size: 1.25rem;
    color: #505457;

}
@media (max-width: 1020px){
    .caption-section{
        width: 65%;
    }
    .joie-caption{
        font-size: 2rem;
    }
}
@media (max-width: 969px){
    .joie-caption{
        margin-bottom: 5px;
    }
    .joie-caption{
        font-size: 2rem;
    }
}
@media (max-width: 1137px){
    .about-us-text{
        line-height: 1.9rem;
        width: 80%;
    
    }
}
@media (max-width: 1093px){
    .about-us-text{
        line-height: 1.6rem;
        width: 80%;
        font-size: 1.1rem;
    
    }
}
@media (max-width: 970px){
    .caption-section{
        width: 60%;
    }
    .joie-caption{
        font-weight: 400;
        font-size: 2.375rem;
    }
    .joie-captext{
        font-weight: 400;
        font-size: 1.25rem;
    }
}
@media (max-width: 937px){
    .about-us-text{
        line-height: 1.5rem;
        width: 80%;
    
    }
}
@media (max-width: 846px){
    .about-us-text{
        line-height: 1.2rem;
        font-size: 0.9rem;
        width: 80%;
    
    }
    .joie-caption{
        font-weight: 400;
        font-size: 2rem;
    }
    .joie-captext{
        font-weight: 400;
        font-size: 1rem;
    }
}
@media (max-width: 733px){
    .about-us-text{
        line-height: 1rem;
        font-size: 0.9rem;
        width: 90%;
    
    }
}
@media (max-width: 700px){
    .about-one-web{
        display: none!important;
    }
    .about-one-mobile{
        display: block!important;
    }
    .caption-section{
        width: 100%;
    }
    .about-us-text{
        width: 100%;
        font-size: 1rem;
        line-height: 1.75rem;
    }
    .joie-caption{
        margin-top: 40px;
        margin-bottom: 40px;
    }
}


.about-one-mobile{
    display: none;
}