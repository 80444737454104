.experience-caption{
    font-family: Dante MT Std;
    color: #2789CA;
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 2.7rem;
}
.experience-text{
    font-family: Objektiv Mk1 Trial;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 2.25rem;
    color: #505457;
}
.experience-mobile{
    display: none;
}
.experience-full-image{
    width: 100%;
}
.experience-notfull-image{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.experience-image-one{
    width: 100%;
}
.experience-image-two{
    width: 100%;
}
@media (max-width:750px) {
    .experience-mobile{
        display: block!important;
    }
    .experience-web{
        display: none!important;
    }
    .experience-text{
        font-size: 1rem;
        line-height: 2rem;
    }
    .experience-caption{
        font-size: 2rem;
        line-height: 2.375rem;
    }
}