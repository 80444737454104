.room-cards{
    padding: 4% 10%;
}
.room-cards-mobile{
    display: none;
}
.room-card-image{
    width: 100%;
}
.room-cards-title{
    font-family: Objektiv Mk1 Trial;
    font-weight: 700;
    font-size: 1rem;
    color: #505457;
}
.room-card-title{
    font-size: 3.063rem;
    font-family: Dante MT Std;
    color: #2789CA;
}
.room-cards-button{
    color: #2789CA!important;
    font-size: 0.875rem!important;
    border: 1px solid #2789CA!important;
}
.room-card-text{
    color: #505457;
    font-size: 0.875rem;
    line-height: 1.181rem;
    font-family: Objektiv Mk1 Trial;
}
.room-cards-button-set{
    position: absolute;
    bottom: 10px;
}

@media(max-width:1080px){
    .room-card-title{
        font-size: 2.563rem;
    }
}
@media(max-width:932px){
    .room-card-title{
        font-size: 2rem;
    }
}
@media(max-width:744px){
    .room-card-title{
        line-height: 2.246rem;
    }
}
@media(max-width:605px){
    .room-card-title{
        line-height: 2.246rem;
    }
    .room-cards-mobile{
        display: block!important;
    }
    .room-cards-web{
        display: none!important;
    }
}