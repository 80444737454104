.join-africa{
    padding-top: 3.188rem;
}
.join-africa-caption{
    font-weight: 400;
    font-size: 4rem;
    font-family: Dante MT Std;
    line-height: 4.12rem;
    color: #FFFFFF;
}
.join-africa-title{
    color: #FFFFFF;
    font-family: Objektiv Mk1 Trial;
    font-size: 1rem;
    font-weight: 700;
}
.join-africa-caption-div{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.join-africa{
    background-size: cover!important;
    
}
.join-africa-buttext{
    font-size: 0.875rem!important;
    color: #2789CA!important;
    border: 1px solid #2789CA;
}
@media (max-width: 500px){
    .join-africa-caption{
        font-size: 2.5rem;
    }
}
@media (max-width: 900px){
    .join-africa{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}