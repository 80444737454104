.room-detail-mobile{
    display: none;
}
.room-detail-title{
    font-weight: 400;
    font-family: Dante MT Std;
    font-size: 2.25rem;
    color: #2789CA;
    margin-top: 18px;
    line-height: 38px;
    margin-bottom: 28px;
}
.room-detail-column2{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 10%;
}
.room-detail-image{
    width: 100%!important;
}
.room-detail-text{
    color: #505457;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 2.25rem;
    margin-bottom: 28px;
    font-family: Objektiv Mk1 Trial;

}
.room-detail-spec{
    font-family: Dante MT Std;
    font-size: 1.7rem;
    color: #505457;
    line-height: 30px;
    font-weight: 400;
}
.room-detail-spec-group{
    margin-bottom: 30px;
}
.room-detail-button{
    color: #2789CA!important;
    border: 1px solid #2789CA!important;
    /* font-weight: 300; */
    /* font-family: Objektiv Mk1 Trial!important; */
    /* font-size: 0.75rem!important; */
}
@media (max-width:1180px) {
    .room-detail-column2{
        width: 95%;
        padding-right: 40px!important;
    }
}
@media (max-width:1148px) {
    .room-detail-title{
        font-size: 2.15rem;
        margin-top: 0px;
        line-height: 38px;
        margin-bottom: 10px;
    }
    .room-detail-text{
        margin-bottom: 10px;
    }
    .room-detail-spec{
        font-size: 1.5rem;
        line-height: 28px;
    }
    .room-detail-spec-group{
        margin-bottom: 10px;
    }
}
@media (max-width:1148px) {
    .room-detail-title{
        line-height: 35px;
    }
    .room-detail-spec{
        line-height: 25px;
    }
    .room-detail-text{
        line-height: 25px;
    }
    .room-detail-column2{
        padding-right: 20px!important;
    }
}
@media (max-width:1000px) {
    .room-detail-mobile{
        display: block!important;
        padding: 4% 10%;
    }
    .room-detail-web{
        display: none!important;
    }
    .room-detail-title{
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .room-detail-text{
        margin-bottom: 20px;
    }
    .room-detail-spec-group{
        margin-bottom: 20px;
    }
}
@media (max-width:700px) {
    .room-detail-spec{
        font-size: 1.25rem;
    }
    .room-detail-text{
        font-size: 1rem!important;
        line-height: 2rem;
    }
}