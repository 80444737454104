.raywaves{
    padding-top: 3.188rem;
}
.raywaves-caption-div{
    max-width: 899px;
    margin-right: auto;
}
.raywaves-caption{
    font-weight: 400;
    font-size: 3.375rem;
    font-family: Dante MT Std;
    line-height: 3.438rem;
    color: #FFFFFF;
}
.raywaves-caption-accent{
    font-weight: 400;
    font-size: 4rem;
    font-family: Amalfi Coast;
    line-height: 4.12rem;
    color: #FFFFFF;
}
.spotify-div{
    background-color: #FFFFFF;
    max-width: 360px;
    margin-left: auto;
    margin-right: 0px;
    width: 100%;
    margin-top: -30px;
}
.playcontrol{
    display: flex;
    justify-content: center;
    align-items: center;
}
.back{
    width: 25px;
    height: 25px;
}
.forward{
    width: 25px;
    height: 25px;
}
/* .pause{
    width: 30px;
    height: 30px;
} */
.ray-track-section{
    display: flex;
}
.butandspotify{
    display:  -webkit-box;
}
.open-spotify{
    color:#3D3D3D;
    border: 1px solid #3D3D3D;
    font-size: 0.875rem;
    font-weight: 500;
    display: inline-flex;
}
.spotify-lower-section{
    display: inline-flex; 
    width: 100%;
    align-items: center;  
}
.see-playlist{
    margin-left: auto;
    margin-right: 0px;
    color: #000000;
    font-family: Objektiv Mk1 Trial;
    font-size: 0.875rem;
    font-weight: 700;
}
.spotartiste{
    color: #3D3D3D;
    font-weight: 500;
    font-size: 0.9rem;
    font-family: Objektiv Mk1 Trial;
}
.spottrack{
    color: #000000;
    font-weight: 600;
    font-size: 1.025rem;
    font-family: Objektiv Mk1 Trial;
}
.ray-waves-buttext{
    font-size: 0.875rem!important;
    color: #2789CA!important;
    border: 1px solid #2789CA!important;
}
.raywaves{
    padding-left: 6.525rem;
    padding-right: 6.525rem;
}
@media (max-width: 755px){
    .butandspotify{
        display: block!important;
    }
    .spotify-div{
        margin-left: auto!important;
        margin-right: auto!important;
        width: 100%;
        margin-top: 150px!important;
        margin-bottom: 50px;
    }
    .ray-wave-butdiv{
        text-align: center;
    }
    .raywaves-caption-div{
        text-align: center;
    }
}
@media (max-width: 565px){
    .raywaves-caption{
        font-size: 2rem;
        line-height: 2.06rem;
    }
    .raywaves-caption-accent{
        font-size: 2rem;
        line-height: 2.06rem;
    }
    .raywaves{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .ray-waves-buttext{
        font-size: 1.063rem!important;
    }
    .raywaves{
        padding-left: 20px;
        padding-right: 20px;
    }
}