.contact-form-web{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
.contact-form-mobile{
    display: none;
}
.react-international-phone-input-container .react-international-phone-input{
    width: -webkit-fill-available!important;
    height: 3rem!important;
}
.react-international-phone-input-container .react-international-phone-country-selector-button{
    height: 3rem!important;
    padding: 1rem!important;
}
.react-international-phone-input-container .react-international-phone-input:focus, input:focus{
    outline: none!important;
}
.contact-form-label{
    color: #025185!important;
    font-family: Objektiv Mk1 Trial!important;
    font-weight: 550!important;
    font-size: 1rem!important;
}
.contact-form-title{
    font-weight: 700;
    font-family: Objektiv Mk1 Trial;
    font-size: 1.125rem;
    color: #3D3D3D;
}
.contact-form-button{
    background-color: #2789CA!important;
    font-family: Objektiv Mk1 Trial!important;
    font-size: 1rem!important;
    font-weight: 700!important;
    color: white!important;
}
.contact-form-button:hover{
    background-color: #0172bd!important;
}
@media (max-width:1000px) {
    .contact-form-web{
        width: 80%;
    }
}
@media (max-width:550px) {
    .contact-form-web{
        width: 90%;
    }
    .contact-form-button{
        width: 100%!important;
    }
}
@media (max-width:480px) {
    .contact-form-web{
        display: none!important;
    }
    .contact-form-mobile{
        display: block!important;
    }
}