/* .founder-card-two-leftgrid{
    padding-top: 100px;
} */
.founder-card-two-rightgrid{
    padding-top: 40px;
}
.founder-card-two-words{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}
.founder-card-two-name{
    font-family: Dante MT Std;
    color: #2789CA;
    font-weight: 400;
    font-size: 3.188rem;
    /* line-height: 4.979rem; */
}
.founder-card-two-button{
    margin-top: 40px;
}
.founder-card-two-title{
    font-family: Objektiv Mk1 Trial;
    font-size: 0.775rem;
    color: #000000;
    font-weight: 400;
    line-height: 1.488rem;
}
.founder-card-two-company{
    font-family: Objektiv Mk1 Trial;
    font-size: 0.775rem;
    color: #000000;
    font-weight: 400;
    line-height: 1.488rem;
    margin-bottom: 20px;
}
.founder-card-two-text{
    font-family: Objektiv Mk1 Trial;
    font-size: 1.025rem;
    font-weight: 400;
    color: #505457;
    line-height: 2rem;
}
.founder-card-two-button{
    color: #2789CA!important;
    font-weight: 500!important;
    font-size: 0.875rem!important;
    border: 1px solid #2789CA!important;
}
.founder-card-two-mobile{
    display: none;
}

@media (max-width:1252px) {
    .founder-card-two-button{
        margin-top: 10px;
    }
}
@media (max-width:1178px) {
    .founder-card-two-words{
        width: 86%;
        padding-right: 10px;
    }
    /* .founder-card-two-leftgrid{
        padding-top: 50px;
    }
    .founder-card-two-rightgrid{
        padding-top: 50px;
    } */
}
@media (max-width:1090px) {
    .founder-card-two-text{
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
@media (max-width:993px) {
    .founder-card-two-text{
        font-size: 1rem;
        line-height: 1.1rem;
    }
}
@media (max-width:885px) {
    .founder-card-two-text{
        font-size: 1rem;
        line-height: 1rem;
    }
    .founder-card-two-button{
        margin-top: 5px;
    }
    .founder-card-two-company{
        margin-bottom: 5px;
    }
}
@media (max-width:845px) {
    .founder-card-two-web{
        display: none!important;
    }
    .founder-card-two-mobile{
        display: block!important;
    }
    .founder-card-two-text{
        font-size: 1.025rem;
        line-height: 2rem;
    }
    .fco-mobile-img{
        margin-top: 40px!important;
    }
    .founder-card-two-name{
        line-height: 2.979rem;
        font-size: 2.25rem;
    }
}
@media (max-width:280px) {
    .founder-card-two-name{
        line-height: 2.979rem;
        font-size: 2.25rem;
    }
}